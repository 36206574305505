import React from 'react';
import {MainAppContext} from '../../App';

const default_profile_image_blue = require("../../imgs/default_profile_image_blue.png");

export default class DashboardSettings extends React.Component {
    static contextType = MainAppContext;

    constructor(props) {
      super(props);
      this.state = {
        selectedFile: null,
        debug: false,

        selectedChangeUsername: ""
      };
      
      this.onSubmitUsernameChange = this.onSubmitUsernameChange.bind(this);
    }

    onSubmitUsernameChange = event => { 
      if(this.state.selectedChangeUsername.length < 2){
        return alert("Your selected username is too small.");
      }

      if (this.props.currUserName === this.state.selectedChangeUsername){
        return alert("Please change your submitted username from the existing username.");
      }

      let reqModifyUsername = this.context.state.internalBackendUserApi.modifyUsername({"data": {"username": this.state.selectedChangeUsername}});
      reqModifyUsername.then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.status === "error"){
            alert(responseJson.message)
        } else if(responseJson.status === "ok"){
          window.location.reload();
        }
        
      })
      .catch((error) => {
          console.error(error);
      });
    }; 
    
    handleBtnClick() {
      /*Collecting node-element and performing click*/
      this.inputFileRef.current.click();
    }

    // On file select (from the pop up) 
    onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 
     
    // On file upload (click the upload button) 
    onFileUpload = () => { 
      if(!this.state.selectedFile){
        alert("File is Invalid, Please Select a file.");
        return null;
      }
      // console.log(this.state.selectedFile.type)
      if(!['image/jpeg', 'image/gif', 'image/png'].includes(this.state.selectedFile.type)) {
        alert("File is Invalid, Please Select a 'jpeg', 'gif' or 'png' file type.");
        return;
      }

      // check file size (< 2MB)
      if(this.state.selectedFile.size > 10 * 1024 * 1024) {
        alert('File must be less than 10MB.');
        return;
      }
      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "file", 
        this.state.selectedFile, 
        this.state.selectedFile.name
      ); 
      /*
      formData.append( 
        "myFile", 
        this.state.selectedFile, 
        this.state.selectedFile.name 
      ); 
      */
     
      // Details of the uploaded file 
      // console.log(this.state.selectedFile); 
     
      // Request made to the backend api 
      // Send formData object 
      let sentImage = this.context.state.internalBackendUserApi.uploadUserProfileImage(formData);
      sentImage.then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.status === "error"){
          if(responseJson.error_type === "image_invalid_type"){
            alert(responseJson.message)
          }
        } else if(responseJson.status === "ok"){
          window.location.reload();
        }
        
      })
      .catch((error) => {
          console.error(error);
      });
    };

    randomString(length, chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
  }
     
    // File content to be displayed after 
    // file upload is complete 
    fileData = () => { 
     
      if (this.state.selectedFile) { 
        return ( 
          <div className="flex flex-col items-center justify-center text-gray-500"> 
            <h2>File Details:</h2>
            <div className="flex items-center">
              <img className="w-32 h-32 mr-4" src={URL.createObjectURL(this.state.selectedFile)} alt="Profile" />
            </div>
            <p>File Name: {this.state.selectedFile.name}</p> 
            <p>File Type: {this.state.selectedFile.type}</p> 
            <p> 
              Last Modified:{" "} 
              {this.state.selectedFile.lastModifiedDate.toDateString()} 
            </p> 
          </div> 
        ); 
      } else { 
        return ( 
          <div className="flex text-gray-500 items-center justify-center py-2">
            <h4>Choose Before Pressing The Upload Button.</h4> 
          </div> 
        ); 
      } 
    };

    render() {

        return (
          <div>
            <div className="bg-white h-auto shadow-md rounded items-center justify-center px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
              <div className="flex-col my-4 mb-8 lg:w-6/12">
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-center text-center my-2">
                      <label class="block text-gray-700 text-sm font-bold" for="password">Username</label>
                      <p class="mx-2 text-red-500 text-xs italic"></p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <input class="shadow appearance-none border border-green-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                             id="username" 
                             type="username"
                             onChange={event => this.setState({selectedChangeUsername: event.target.value})}
                             placeholder={this.props.currUserName} />
                      <button className="bg-green-500 hover:bg-teal-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded"
                              onClick={this.onSubmitUsernameChange}>Change</button>
                    </div>
                  </div>
              </div>

              <div className="flex flex-col items-center justify-center border-b border-b-2 border-opacity-25 border-teal-500 py-2">
                <div className="flex flex-row items-center justify-center text-center my-2">
                      <label class="block text-gray-700 text-sm font-bold" for="password">Profile Image</label>
                      <p class="mx-2 text-red-500 text-xs italic"></p>
                    </div>
                {this.props.currUserProfileImage && !this.state.selectedFile ? 
                  <img className="w-20 h-20 rounded-full mr-4 mb-4" src={this.props.currUserProfileImage ? this.props.currUserProfileImage : default_profile_image_blue} alt="Profile" />
                :
                  <img className="w-20 h-20 rounded-full mr-4 mb-4" src={this.state.selectedFile ? URL.createObjectURL(this.state.selectedFile) : default_profile_image_blue} alt="Profile" />
                }
                <div className="flex flex-row items-center justify-center text-center">
                  <input className="flex text-white font-bold rounded opacity-50 w-32" type="file" onChange={this.onFileChange} />
                  <button onClick={this.onFileUpload} className="bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded">
                    UPLOAD
                  </button>
                </div>
              </div>
              {this.fileData()} 
            </div>
          </div>
          );
    }
  }