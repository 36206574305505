export const userStore = {
    "chelsea": {
    currUserNameReal: "Chelsea No",
    companyName: "",
    companyIcon:
      "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/2_bnfaa3ftO.png?updatedAt=1725298053117",
    currUserLinks: [
      {
        url: "https://www.facebook.com/groups/1272710266712288/",
        title: "Join Our Intentional Facebook Community",
        type: 'link',
      },
      {
        url: "https://www.eventbrite.com/o/chelsea-no-52701807213",
        title: "Check out my Upcoming Events!",
        type: 'event-bright',
      },
    ],
    socials: {
      facebook: 'https://www.facebook.com/glowandgetit/',
      instagram: 'https://www.instagram.com/collectiveself.co/',
      youtube: 'https://www.youtube.com/channel/UCRNhE3lOzN5JgJGanL2Frlw',
    },
    currUserProfileImage:
      "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/369295289_677887351043918_6084207100066258780_n%20(2)_3-uyXzAPP.jpg?updatedAt=1725299030372",
    userTagline: "create. conscious. community.",
    userQRCodeDescription: "View My Classes, Events, and Wellness Information",
    userBio: "Hi, 👋 I’m Chelsea No, a yoga instructor and the founder of CollectiveSELF! I’m passionate about creating a welcoming space where you can connect with your body, mind, and spirit through mindful movement. I’d love to guide you on your journey of self-discovery and growth!",
    integrations: {
      docuSeal: {
        text: "Sign your Waiver for your class 🖊️",
        url: "https://docuseal.co/d/ApbTRRyVQvokkJ"
      }
    },
    testimonials: [
      {
        text: "Before my first class with Chelsea I hadn't done yoga in years, but within the first 10 minutes, I knew I'd be back for more! The flow was gentle, yet challenging enough to awaken my muscles and give me a feeling of strength. I immediately felt re-connected to my body. I'm hooked!  ",
        name: "A. Washington",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "I have been going to Chelsea yoga class for about 2 years and we worked on multiple different poses. She has taught me so much! Her classes in Yoga and Meditation have helped me relax more daily and improved my mobility.",
        name: "S. Baker",
        role: "Student",
        profileImg:
          "https://api.dicebear.com/9.x/bottts-neutral/svg?seed=Bubba",
      },
      {
        text: "My first yoga class with Chelsea was through Zoom, a few of my friends and I got together in my living-room  to enjoy a class together. Chelsea made the class very adaptable for all of us ladies of various abilities and there was no pressure for us to be perfect. The class was very relaxing and provided me, personally, with much needed stretching to help me tolerate the pain from a back injury in December. Since this class, I have been able to enjoy an in-person community yoga session outside that was very peaceful and empowering",
        name: "M. Stevens ",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "I attended a class that Chelsea No of Collectiveself.co held centered on confidence. I left feeling so much more centered in my body, confident in my abilities, and at ease about my vision for the future. Chelsea incorporated breath work and visualization meditation in her practice to embody the full practice of yoga. She was clear in her instructions, moved at a great pace, and was so genuine. You can tell that she pours love, time, and intention into the spaces she curates, which makes them unique. It’s a safe, welcoming, intentional space to invest in yourself. Thank you, Chelsea!",
        name: "L. Marie",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "I enjoyed my flow of embracing confidence with Chelsea because it inspired me to fully show up as myself. This previous class also inspired me to apply my intentions during my practice to my everyday life.. my intention during the class was to take up space. I genuinely enjoyed connecting with others that attended the class as well.",
        name: "K. Cuyler",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "I had a private, targeted stretching session for my lower back pain with Chelsea. After that session, my back felt much better and I was able to perform my normal daily activities pain-free.",
        name: "T. Turner ",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=Lucy",
      },
    ],
    previousEvents: [
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/720a4e60-eb39-42b7-9dd3-3074c2aaaeec_-q_EXIT5s.jfif?updatedAt=1725304044377",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/a5be98bc-3061-41da-9833-5bb70982e92f_QcRPrlf7O.jfif?updatedAt=1725304632799",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/94b34b15-208a-42a4-91dc-bd1db2811d92_7ai_TZ39S.jfif?updatedAt=1725304044208",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/992cd18f-4dd7-405e-9d7f-8da8e64bee2d_j_T_Dw822.jfif?updatedAt=1725304044181",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/132a0925-3980-4e7a-b4c8-23525b084ccb_234LV0CmQ.jfif?updatedAt=1725304044059",
      },
    ],
    partnerships: [
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/salt%20city3-05_srH_sPoki.jpg?updatedAt=1725305639713",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/The%20SYC%20logo%20print%20version%20(transparent)%20%20(2)_bRuwrXuWc.png?updatedAt=1725305639726",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/chelsea_t/Black%20Minimalist%20Mandala%20Flower%20Logo_cpGIOhDEc.jpg?updatedAt=1725305639554",
      },
    ],
    ratesConfiguration: {
      personal_in_studio: {
        label: "Personal (In-Studio)",
        intakeForm: "https://tally.so/r/wLY72z",
        paymentLink: "https://krayla.com/chelsea",
        payments: [
          {
            label: "Private Class (In Studio)",
            isPrimary: true,
            price: 35,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 35,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 60,
                      paymentLink:
                        "https://krayla.com/chelsea?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Meditation (In Studio)",
            isPrimary: false,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 30,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 50,
                      paymentLink:
                        "https://krayla.com/chelsea?p=60",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      personal_online: {
        label: "Personal (Online)",
        intakeForm: "https://tally.so/r/wLY72z",
        paymentLink: "https://krayla.com/chelsea",
        payments: [
          {
            label: "Private Class (Online)",
            isPrimary: true,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 30,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 50,
                      paymentLink:
                        "https://krayla.com/chelsea?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Meditation (Online)",
            isPrimary: false,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 25,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 45,
                      paymentLink:
                        "https://krayla.com/chelsea?p=60",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      personal_in_home: {
        label: "Personal (In-Home)",
        intakeForm: "https://tally.so/r/wLY72z",
        paymentLink: "https://krayla.com/chelsea",
        payments: [
          {
            label: "Private Class (In-Home)",
            description:
              "A single personalized class tailored to you specific needs. Great for returning to your practice, or as a meaningful gift.",
            isPrimary: true,
            price: 75,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 75,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 150,
                      paymentLink:
                        "https://krayla.com/chelsea?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "4 Class Bundle (In-Home)",
            description:
              "4 Personalized In-Home Classes designed for your personal needs and wellness goals. Each session will build upon the last and allow you to create a deeper connection with yourself. Gift certificates available.",
            isPrimary: false,
            price: 225,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 225,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 30,
                      price: 450,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                  ],
                },
              },
              {
                id: "total_class",
                text: "4 Classes",
              },
              {
                id: "max_person",
                text: "Up to 3 people",
              },
            ],
          },
          {
            label: "8 Class Bundle (In-Home)",
            description:
              "8 Personalized In-Home Classes designed for your personal needs and wellness goals. Each session will build upon the last and allow you to create a deeper connection with yourself.",
            isPrimary: false,
            price: 360,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 60,
                      price: 360,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 720,
                      paymentLink:
                        "https://krayla.com/chelsea?p=30",
                    },
                  ],
                },
              },
              {
                id: "total_class",
                text: "8 Classes",
              },
              {
                id: "max_person",
                text: "Up to 3 people",
              },
            ],
          },
        ],
      },
      // organizational: {
      //   label: 'Organizational',
      //   paymentLink: 'https://google.com/p=30',
      //   payments: [
      //     {
      //       label: 'Private Class (In Studio)',
      //       isPrimary: true,
      //       price: 999.99,
      //       data: [
      //         {
      //           id: 'class_length',
      //           text: 'Class Length',
      //           dropdown: {
      //             value: null,
      //             values: [
      //               {
      //                 text: '30 min',
      //                 value: 30,
      //                 price: 545.99,
      //                 paymentLink: 'https://google.com/p=30'
      //               },
      //               {
      //                 text: '60 min',
      //                 value: 60,
      //                 price: 2544.99,
      //                 paymentLink: 'https://google.com/p=60'
      //               },
      //             ]
      //           },
      //         }
      //       ]
      //     },
      //     {
      //       label: 'Meditation (In Studio)',
      //       isPrimary: false,
      //       price: 24.99,
      //       data: [
      //         {
      //           id: 'class_length',
      //           text: 'Class Length',
      //           dropdown: {
      //             value: null,
      //             values: [
      //               {
      //                 text: '30 min',
      //                 value: 30,
      //                 price: 343.99,
      //                 paymentLink: 'https://google.com/p=30'
      //               },
      //               {
      //                 text: '60 min',
      //                 value: 60,
      //                 price: 4646.99,
      //                 paymentLink: 'https://google.com/p=60'
      //               },
      //             ]
      //           },
      //         }
      //       ]
      //     }
      //   ]
      // }
    },
    isLoading: false,
    },
    "demo_stacy": {
    currUserNameReal: "Stacy Reads",
    companyName: "",
    companyIcon:
      "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/stacy_r/Stacy's%20Reads%20Co._T7uz30xmz.png?updatedAt=1725552277721",
    currUserLinks: [
      {
        url: "https://www.audiable.com/",
        title: "Join Our Reading Group",
      },
    ],
    socials: {
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      youtube: 'https://www.youtube.com',
    },
    currUserProfileImage:
      "https://api.dicebear.com/9.x/micah/svg?seed=Pepper",
    userTagline: "Happiness. Joy. Peace.",
    userQRCodeDescription: "View My Classes, Events, and Wellness Information",
    userBio: "Hello 👋, I'm Stacy Reads, a book-loving event planner, crafting immersive literary experiences, connecting readers, authors, and stories in unique settings.",
    integrations: {
      docuSeal: {
        text: "Sign your Waiver for your class 🖊️",
        url: "https://docuseal.co/d/5K7AdqfNm7gNL1"
      }
    },
    testimonials: [
      {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc volutpat volutpat placerat. Nam sodales tempor arcu in placerat. Proin ut suscipit nulla. Maecenas at scelerisque nisl, at fringilla diam. Vivamus id nisi facilisis, malesuada elit nec, maximus sem. Nulla facilisi. Phasellus egestas ut nunc sed lobortis.",
        name: "A. Hozier",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "Pellentesque tempor, odio sed pharetra euismod, diam libero egestas velit, eget luctus lacus eros eu nisi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed eleifend risus sed quam venenatis, molestie imperdiet leo maximus. Maecenas non cursus tellus, nec facilisis nunc. ",
        name: "S. Blums",
        role: "Student",
        profileImg:
          "https://api.dicebear.com/9.x/bottts-neutral/svg?seed=Bubba",
      },
      {
        text: "Suspendisse aliquam lacus nec tortor consequat, sit amet sollicitudin sapien faucibus. Pellentesque at eros at turpis condimentum aliquet ac id enim. Suspendisse ante felis, malesuada at ultricies et, maximus ut tellus. Nulla facilisi. Integer blandit tortor in lorem elementum aliquet. Sed in augue ex.",
        name: "M. Johnson",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "Maecenas at scelerisque nisl, at fringilla diam. Vivamus id nisi facilisis, malesuada elit nec, maximus sem. Nulla facilisi. Phasellus egestas ut nunc sed lobortis.",
        name: "L. Raff",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "Donec vestibulum quam mi, quis blandit ligula pellentesque eu. Nam sed magna dapibus magna commodo interdum. Curabitur aliquet eu tortor in pellentesque. Morbi pharetra eget neque sit amet euismod.",
        name: "K. Bonsart",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=",
      },
      {
        text: "Integer volutpat imperdiet orci, vitae laoreet tellus laoreet sed. Aliquam quis diam sit amet justo cursus vulputate et id quam. Quisque vestibulum gravida viverra. Nulla vel varius magna. Curabitur diam lacus, placerat eu rhoncus non, fermentum et nisl. Nulla neque eros, pretium in elit et, hendrerit posuere enim. In ac enim finibus, hendrerit lacus vel, egestas sem. Maecenas pulvinar tortor sed libero sodales suscipit.",
        name: "T. Apples ",
        role: "Student",
        profileImg: "https://api.dicebear.com/9.x/micah/svg?seed=Lucy",
      },
    ],
    previousEvents: [
    {
        text: "",
        image:
            "https://images.unsplash.com/photo-1594639738432-ea7a76cd466c?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fHJlYWRpbmclMjBiYWJ5fGVufDB8fDB8fHww",
    },
      {
        text: "",
        image:
          "https://images.unsplash.com/photo-1455884981818-54cb785db6fc?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHJlYWRpbmclMjBiYWJ5fGVufDB8fDB8fHww",
      },
      {
        text: "",
        image:
          "https://images.unsplash.com/photo-1513542789411-b6a5d4f31634?q=80&w=3774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        text: "",
        image:
          "https://images.unsplash.com/photo-1523865236457-3ae3358a4eaa?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGJvb2tzfGVufDB8fDB8fHww",
      },
      
    ],
    partnerships: [
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/stacy_r/default_9fdZlBM5O.png?updatedAt=1725643534021",
      },
      {
        text: "",
        image:
          "https://ik.imagekit.io/ttaqwrejqib/project-light-blazer/stacy_r/default(1)_pap11rO_s.png?updatedAt=1725643720559",
      },
    ],
    ratesConfiguration: {
      personal_in_studio: {
        label: "Personal (In-Studio)",
        intakeForm: "https://tally.so/r/mYr8MJ",
        paymentLink: "https://dashboard.stripe.com/register/payment_links",
        payments: [
          {
            label: "Private Class (In Studio)",
            isPrimary: true,
            price: 35,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 35,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 60,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Meditation (In Studio)",
            isPrimary: false,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 30,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 50,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=60",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      personal_online: {
        label: "Personal (Online)",
        intakeForm: "https://tally.so/r/mYr8MJ",
        paymentLink: "https://dashboard.stripe.com/register/payment_links",
        payments: [
          {
            label: "Private Class (Online)",
            isPrimary: true,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 30,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 50,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Meditation (Online)",
            isPrimary: false,
            price: 30,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 25,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 45,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=60",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      personal_in_home: {
        label: "Personal (In-Home)",
        intakeForm: "https://tally.so/r/mYr8MJ",
        paymentLink: "https://dashboard.stripe.com/register/payment_links",
        payments: [
          {
            label: "Private Class (In-Home)",
            description:
              "A single personalized class tailored to you specific needs. Great for returning to your practice, or as a meaningful gift.",
            isPrimary: true,
            price: 75,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 75,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 150,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=60",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "4 Class Bundle (In-Home)",
            description:
              "4 Personalized In-Home Classes designed for your personal needs and wellness goals. Each session will build upon the last and allow you to create a deeper connection with yourself. Gift certificates available.",
            isPrimary: false,
            price: 225,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 30,
                      price: 225,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 30,
                      price: 450,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                  ],
                },
              },
              {
                id: "total_class",
                text: "4 Classes",
              },
              {
                id: "max_person",
                text: "Up to 3 people",
              },
            ],
          },
          {
            label: "8 Class Bundle (In-Home)",
            description:
              "8 Personalized In-Home Classes designed for your personal needs and wellness goals. Each session will build upon the last and allow you to create a deeper connection with yourself.",
            isPrimary: false,
            price: 360,
            data: [
              {
                id: "class_length",
                text: "Class Length",
                dropdown: {
                  value: null,
                  values: [
                    {
                      text: "30 min",
                      value: 60,
                      price: 360,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                    {
                      text: "60 min",
                      value: 60,
                      price: 720,
                      paymentLink:
                        "https://dashboard.stripe.com/register/payment_links?p=30",
                    },
                  ],
                },
              },
              {
                id: "total_class",
                text: "8 Classes",
              },
              {
                id: "max_person",
                text: "Up to 3 people",
              },
            ],
          },
        ],
      },
      // organizational: {
      //   label: 'Organizational',
      //   paymentLink: 'https://google.com/p=30',
      //   payments: [
      //     {
      //       label: 'Private Class (In Studio)',
      //       isPrimary: true,
      //       price: 999.99,
      //       data: [
      //         {
      //           id: 'class_length',
      //           text: 'Class Length',
      //           dropdown: {
      //             value: null,
      //             values: [
      //               {
      //                 text: '30 min',
      //                 value: 30,
      //                 price: 545.99,
      //                 paymentLink: 'https://google.com/p=30'
      //               },
      //               {
      //                 text: '60 min',
      //                 value: 60,
      //                 price: 2544.99,
      //                 paymentLink: 'https://google.com/p=60'
      //               },
      //             ]
      //           },
      //         }
      //       ]
      //     },
      //     {
      //       label: 'Meditation (In Studio)',
      //       isPrimary: false,
      //       price: 24.99,
      //       data: [
      //         {
      //           id: 'class_length',
      //           text: 'Class Length',
      //           dropdown: {
      //             value: null,
      //             values: [
      //               {
      //                 text: '30 min',
      //                 value: 30,
      //                 price: 343.99,
      //                 paymentLink: 'https://google.com/p=30'
      //               },
      //               {
      //                 text: '60 min',
      //                 value: 60,
      //                 price: 4646.99,
      //                 paymentLink: 'https://google.com/p=60'
      //               },
      //             ]
      //           },
      //         }
      //       ]
      //     }
      //   ]
      // }
    },
    isLoading: false,
    }
}