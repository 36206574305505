
import React from 'react';
import {MainAppContext} from '../../App';

class ViewLink extends React.Component {
  static contextType = MainAppContext;
  constructor(props) {
    super(props);
    this.state = {
      dataLid: this.props.data.lid,
      dataTitle: this.props.data.title,
      dataUrl: this.props.data.url,
      dataEnabled: this.props.data.enabled,

      AlertShown: false,
      AlertType: "alert",
      AlertColorClass: "bg-teal-100 border-teal-500",
      AlertColorClasses: {
        alert: "bg-teal-100 border-teal-500 text-teal-500",
        error: "bg-red-100 border-red-500 text-red-500"
      }
    };

    this.modifyAddLink = this.modifyLink.bind(this);
  }

  deleteLink(event){
    let that = this;
    var p =  {
        "data":{
            "lid": this.state.dataLid,
      }
    }
    let resp = this.context.state.internalBackendUserApi.deleteUserLink(p);
    resp.then((response) => response.json())
    .then((responseJson) => {
      that.setState({AlertShown: true, AlertColorClass: this.state.AlertColorClasses.error});
      setTimeout(() => (that.setState({AlertShown: false})), 3000);
    })
    .catch((error) => {
        console.error(error);
    });
  }

  modifyLink(event){
    let that = this;
    var p =  {
        "data":{
            "lid": this.state.dataLid,
            "title": this.state.dataTitle,
            "url": this.state.dataUrl,
            "enabled": this.state.dataEnabled
      }
    }
    // console.log("Data Input to update", p);
    let resp = this.context.state.internalBackendUserApi.modifyUserLink(p);
    resp.then((response) => response.json())
    .then((responseJson) => {
      that.setState({AlertShown: true, AlertColorClass: this.state.AlertColorClasses.alert});
      setTimeout(() => (that.setState({AlertShown: false})), 3000);
    })
    .catch((error) => {
        console.error(error);
    });
  }

  render() {
    let {data} = this.props;
    return (
      <div className="w-full border-t border-r border-b border-l border-gray-400 my-2 lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="mb-2">
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-600 flex items-center normal-case truncate">{data.title}</p>
            <a className="text-blue-700 underline" target="_blank" rel="noopener noreferrer" href={data.url} >Visit</a>
          </div>
          <div className="border-b border-b-2 my-2">
            <input className="text-center appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none placeholder-blue-500"
                  type="text"
                  onChange={(e) => (this.setState({ dataTitle: e.target.value }))}
                  defaultValue={data.title}
                  aria-label="Title"/>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-sm w-full">
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => (this.setState({ dataUrl: e.target.value }))}
                type="text"
                defaultValue={data.url}/>
          <p className="text-gray-600 hidden">Aug 18</p>
          </div>
        </div>
        <hr className="my-2"/>
        <div className="flex items-center justify-between">
          <label htmlFor={`button-toggle-item-${this._reactInternalFiber.key}`} className="flex items-center cursor-pointer">
            <div className="relative">
              <input id={`button-toggle-item-${this._reactInternalFiber.key}`}
                    defaultChecked={data.enabled}
                    onChange={(e) => (this.setState({ dataEnabled: e.target.checked }))}
                    type="checkbox"
                    className="hidden" />
              <div className="button-toggle-line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
              <div className="button-toggle-switch absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" style={{top: "-.25rem",left: "-.25rem"}}></div>
            </div>
            <div className="ml-3 text-gray-700 font-medium">Enabled</div>
          </label>
          <div className="flex items-center justify-between">
            <button className="bg-indigo-400 hover:bg-indigo-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mx-1"
                    onClick={(event) => (this.modifyLink(event))}>
              <span>Apply</span>
            </button>
            <button className="bg-red-500 hover:bg-red-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mx-1"
                    onClick={(event) => (this.deleteLink(event))}>
              <span>Delete</span>
            </button>
          </div>
        </div>
        {this.state.AlertShown ? 
          <div className={`${this.state.AlertColorClass} border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md my-2 transition duration-500 ease-in-out`} role="alert">
            <div className="flex">
              <div className="py-1"><svg className="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
              <div>
                <p className="font-bold">Link Modified!</p>
                <p className="text-sm">You should see these changes immediately. <span aria-label="cool glasses" role="img">😎</span></p>
              </div>
            </div>
          </div>
        : 
          null
        }
    </div>
    );
  }
}

class AddLink extends React.Component {
  static contextType = MainAppContext;
  constructor(props) {
    super(props);
    this.state = {
      UserLinkTitle: '',
      UserLinkUrl: '',
      UserLinkEnable: true
    };

    // Bindings
    this.apiAddLink = this.apiAddLink.bind(this);
  }

  apiAddLink(event){
    let p =  {
        "data":{
            "title": this.state.UserLinkTitle,
            "url": this.state.UserLinkUrl,
            "enabled": this.state.UserLinkEnable
        }
      }
      let resp = this.context.state.internalBackendUserApi.addUserLink(p);
      resp.then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.status === "ok"){
          window.location.reload();
        }
      })
      .catch((error) => {
          console.error(error);
      });
  }

  render() {
    let {data} = this.props;
    return (
      <div className="w-full border-t border-r border-b border-l border-gray-400 my-2 lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="mb-2">
          <p className="text-sm text-gray-600 flex items-center normal-case">Add A New Link!</p>
          <div className="border-b border-b-2 my-2">
            <input className="text-center appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none placeholder-blue-500" onChange={event => this.setState({"UserLinkTitle": event.target.value})}  type="text" placeholder={data.title} aria-label="Title"/>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-sm w-full">
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" onChange={event => this.setState({"UserLinkUrl": event.target.value})} type="text" placeholder={data.url}/>
          <p className="text-gray-600 hidden">Aug 18</p>
          </div>
        </div>
        <hr className="my-2"/>
        <div className="flex items-center justify-between">
          <label htmlFor="button-toggle-create" className="flex items-center cursor-pointer">
            <div className="relative">
              <input className="hidden" id="button-toggle-create" type="checkbox" defaultChecked={true} onChange={event => this.setState({"UserLinkEnable": event.target.checked})} />
              <div className="button-toggle-line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
              <div className="button-toggle-switch absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" style={{top: "-.25rem",left: "-.25rem"}}></div>
            </div>
            <div className="ml-3 text-gray-700 font-medium">Enabled</div>
          </label>
          <button className="bg-yellow-500 hover:bg-yellow-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={(event) => this.apiAddLink(event)}>
            <span>Add</span>
          </button>
        </div>
    </div>
    );
  }
}

export default class DashboardLinks extends React.Component {
    static contextType = MainAppContext;
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      let {currUserLinks} = this.props;
      return (
        <div className="flex flex-col my-2 items-center justify-center">
          <AddLink data={{"url": "http://www.addmywesitehere.com", "title": "Fill Your Title Here...."}}/>
          {currUserLinks ? 
            <div className="flex w-full flex-col my-2 items-center justify-center">
              <hr className="my-2" />
              <h3 className="text-lg my-4 text-indigo-600 border-b flex text-center normal-case"><span aria-label="raise the roof" role="img">🙌</span> Your Links! <span aria-label="celebration" role="img">🎉</span></h3>
              {Object.keys(currUserLinks).map((data, key) => <ViewLink key={data} data={Object.assign({"lid": data}, currUserLinks[data])} />)}
            </div>
          : null}
          </div>
      );
    }
  }