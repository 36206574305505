import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import * as reactRouterDom from "react-router-dom";

import ScaleLoader from "react-spinners/ScaleLoader";
import { SocialIcon } from 'react-social-icons';

/* CSS */
import './App.css';
import './SliderAnimation.css';

/* SVG(s) */
import { default as LiquidLinkMobileMockupImage }  from './imgs/liquidlink_mobile_mockup.svg';
import { default as LiquidLinkInstagramMobileMockupImage } from './imgs/liquidlink_instagram_mockup.svg';
import { ReactComponent as LiquidLinkLogo } from './imgs/liquidlink_logo.svg';

/* Context */
import MainAppContext from './Contexts';

/* Relative Imports */
import BackendUserApi from './js/api';

/* Views */
import UserView from './views/UserView';
import DashboardOverview from './views/dashboard/overview'; 
import DashboardLinks from './views/dashboard/links';
import DashboardSettings from './views/dashboard/settings'; 

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faSpotify, faTwitch, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

library.add(faInstagram, faYoutube, faSpotify, faTwitch, faTwitter, faArrowDown)

const default_profile_image_blue = require("./imgs/default_profile_image_blue.png");



SuperTokens.init({
  appInfo: {
      appName: "http://localhost:3000/",
      apiDomain: "http://localhost:8000",
      websiteDomain: "http://localhost:3000",
      apiBasePath: "/auth",
      websiteBasePath: "/m/login"
  },
  recipeList: [
      Passwordless.init({
          contactMethod: "EMAIL_OR_PHONE"
      }),
      Session.init()
  ]
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cotter: {
        getLoggedInUser: () => {}
      },
      internalBackendUserApi: new BackendUserApi(process.env.REACT_APP_INTERNAL_BACKEND_USER_API_HOSTNAME)
    };

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
  }

  isAuthenticated = () => {
      return this.state.cotter.getLoggedInUser() === null ? false: true;
  }

  /**
   * 
   * @param {*} path = The path to go to.
   * @param {*} time = Wait time to redirect
   */
  redirectUser = (path, time) => {
    setTimeout(() => {
      window.location.href = path;
    }, time);
  }

  render() {
     let isAuth = this.isAuthenticated()
    return (
      <MainAppContext.Provider value={this}>
        <SuperTokensWrapper>
        <Router>
          <Routes>
            <Route path="/m/about" element={<About />}>
              
            </Route>
            {/* Auth Required Views */}
            {isAuth ?
              <Route path="/m/dashboard" element={<Dashboard />}>
              </Route>
            : 
              <Route path="/m/dashboard" element={<UserNotAuthRedirect />}>
              </Route>
            }
            <Route path="/m/login" element={<LoginRegister actionType="login" />}>
            </Route>
            <Route path="/m/register" element={<LoginRegister actionType="register"/>}>
            </Route>
            <Route exact path="/" element={<Home />}>
            </Route>
            <Route path="*" element={<UserPagePath />}>
            </Route>
            </Routes>
        </Router>
      </SuperTokensWrapper>
    </MainAppContext.Provider>
    );
  }
}

function Home(){
  return (
    <div className="leading-normal tracking-normal text-gray-900 overflow-x-hidden">
      <div className="h-screen pb-14 bg-right bg-cover">
        <div className="text-center lg:px-4">
          <div className="p-2 bg-blue-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span className="flex rounded-full uppercase px-2 py-1 text-2xl font-bold mr-3"><span aria-label="wave" role="img">👋</span></span>
            <span className="font-semibold mr-2 text-left flex-auto capitalize">Sign up now to start utilizing our powerful tools!</span>
          </div>
        </div>
        <div className="w-full container mx-auto p-6 border-b">
          <div className="w-full flex items-left justify-between">
            <div className="w-full flex items-center justify-start">
            <LiquidLinkLogo className="home-logo" onClick={() => window.location.href = "/"}/>
            </div>
            <div className="w-full flex items-center justify-end">
              <a href="/m/login" className="underline mx-3 font-bold">Login</a>     
              <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={() => window.location.href = "/m/register"}>
                Register
              </button>
            </div>
          </div>
        </div>

        <div className="container flex items-center justify-center pt-6 md:pt-6 px-6 mx-auto flex-wrap flex-col md:flex-row items-center">
          <div className="flex flex-col w-full xl:w-2/5 justify-center overflow-y-hidden">
            <h1 className="mt-4 text-5xl md:text-5xl font-bold leading-tight text-left md:text-center slide-in-bottom-h1">One Link For</h1>
            <h1 className="mb-4 text-6xl md:text-6xl font-bold leading-tight text-left md:text-center slide-in-bottom-h1">Them All</h1>
            <p className="leading-normal mx-4 font-semibold capitalize text-base md:text-2xl mb-4 text-left md:text-center slide-in-bottom-subtitle">With LiquidLink, You Can Centralize all of your Social Platforms and Content to One Link for all of your audience.</p>
            <button className="animate__animated animate__tada animate__delay-1s bg-purple-500 hover:bg-purple-400 w-4/6 text-white font-bold py-2 mb-2 self-center px-4 border-b-4 border-purple-600 hover:border-purple-500 rounded"
                    onClick={() => window.location.href = "/m/register"}>GET STARTED FOR FREE</button>
          </div>
        </div>

        <div className="flex flex-col w-full justify-center md:justify-center my-4 pb-4 lg:pb-0 fade-in">
          <div className="slider mb-4">
            <div className="flex flex-row slide-track">
              <div className="flex slide items-center justify-center">
                <SocialIcon network="dribbble" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="instagram" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="youtube" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="twitch" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                  personal.blog.com
                </button>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="behance" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                  email.business@mail.com
                </button>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="github" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                  Newsletter Subscribe
                </button>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="meetup" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="pinterest" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="reddit" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="snapchat" style={{ height: 75, width: 75 }}/>
              </div>
              <div className="flex slide items-center justify-center">
                <SocialIcon network="facebook" style={{ height: 75, width: 75 }}/>
              </div>
            </div>
          </div>
          <img className="animate__animated animate__tada animate__delay-3s p-4 self-center lg:py-4 lg:w-2/12 w-11/12" src={LiquidLinkInstagramMobileMockupImage} />
          <FontAwesomeIcon className="self-center" size="6x" icon={faArrowDown} color="gray" />
          <img className="animate__animated animate__headShake animate__delay-5s p-4 self-center lg:py-4 lg:w-2/12 w-11/12" src={LiquidLinkMobileMockupImage} />
        </div>
        
        <div className="container flex items-center justify-center pt-6 md:pt-6 px-6 mx-auto flex-wrap flex-col md:flex-row items-center bg-gray-100">
          <div className="flex flex-col w-full xl:w-2/5 justify-center overflow-y-hidden">
            <h1 className="my-4 text-3xl md:text-3xl font-bold leading-tight text-center md:text-center slide-in-bottom-h1">It's Basically and Micro-Website for your Links</h1>
            <p className="leading-normal mx-4 font-semibold capitalize text-base md:text-2xl mb-4 text-center md:text-center slide-in-bottom-subtitle">You can create a page in minutes and design it with endless possibilities to share to your amazing audience.</p>
            <button className="bg-gray-200 w-4/6 text-black font-bold py-2 mb-2 self-center px-4 border-purple-600 rounded mb-8"><h1 className="inline text-blue-400">liquidlink.me</h1>/YourHandle</button>
          </div>
        </div>
      
        <div className="container flex items-center justify-center pt-6 md:pt-6 px-6 mx-auto flex-wrap flex-col md:flex-row items-center bg-gray-200">
          <div className="flex flex-col justify-center overflow-y-hidden mb-8">
            <h1 className="my-4 text-3xl md:text-3xl font-bold leading-tight text-center md:text-center slide-in-bottom-h1">Some of your endless choices!</h1>            
            
            <div style={{backgroundColor: "#C13584"}} className="max-w-sm rounded overflow-hidden shadow-lg bg-white my-2">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-white"><FontAwesomeIcon icon={faInstagram} color="white" /> Instagram</div>
                <p className="text-white">
                  Share all your amazing photo and creative art to with audience!
                </p>
              </div>
            </div>

            <div style={{backgroundColor: "#FF0000"}} className="max-w-sm rounded overflow-hidden shadow-lg bg-white my-2">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-white"><FontAwesomeIcon icon={faYoutube} color="white" /> Youtube</div>
                <p className="text-white">
                  Reach even more subscribers and grow your channel to new heights!@#$
                </p>
              </div>
            </div>

            <div style={{backgroundColor: "#1DA1F2"}} className="max-w-sm rounded overflow-hidden shadow-lg my-2">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-white"><FontAwesomeIcon icon={faTwitter} color="white" /> Twitter</div>
                <p className="text-white">
                  Let's the tweets fly... but make sure all your audience can see what your talking about.
                </p>
              </div>
            </div>

            <div style={{backgroundColor: "#1DB954"}} className="max-w-sm rounded overflow-hidden shadow-lg bg-white my-2">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-white"><FontAwesomeIcon icon={faSpotify} color="white" /> Spotify</div>
                <p className="text-white">
                  Let's all the music and have a jam session with you followers.
                </p>
              </div>
            </div>

            <div style={{backgroundColor: "#6441A4"}} className="max-w-sm rounded overflow-hidden shadow-lg bg-white my-2">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-white"><FontAwesomeIcon icon={faTwitch} color="white" /> Twitch</div>
                <p className="text-white">
                  Lights. camera. action! The show must go on, to let your follows know where you are!
                </p>
              </div>
            </div>
          
          </div>
          
          <button className="bg-green-500 hover:bg-green-400 w-4/6 text-white font-bold py-4 mb-2 self-center px-4 border-b-4 border-green-600 hover:border-green-500 rounded-full shadow mb-8"
                    onClick={() => window.location.href = "/m/register"}>SIGN UP FOR FREE</button>
        </div>
        

        <div className="flex flex-row w-full justify-center w-full text-sm text-left md:text-left fade-in my-2 mx-1">
          <a className="text-black-500 no-underline hover:no-underline mr-2" href="/">&copy; LiquidLink 2020</a>
          <div className="flex self-center items-center justify-center">
            <a className="text-center mr-2 underline" href="/">Terms & Conditions</a>
            <a className="text-center underline" href="/">Privacy Policy</a>
          </div>
        </div>
      </div>
        
    </div>
  );
}
function About() {
  return (<h2>About</h2>);
}

class UserNotAuthRedirect extends React.Component {
  static contextType = MainAppContext;
  
  componentDidMount() {
    this.context.redirectUser("/m/login", 5000);
  }

  render (){
    return (
      <div className="overflow-visible flex-col items-center justify-center mt-24">
        <h3 className="font-bold text-center">ERROR_NOT_LOGGED_IN</h3>
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-5" role="alert">
          <strong className="font-bold">Errr - Seems your not logged in!</strong>
          <span className="block">Something seriously bad happened, But we'll point you in the right direction!</span>
        </div>
      </div>);
  }
}

class Dashboard extends React.Component {
  static contextType = MainAppContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: window.location.hash.length > 0 ? window.location.hash.slice(1) : "overview",
      selectedClass: "bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold",
      normalClass: "bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold",

      currUserName: null,
      currUserLinks: null,
      currUserProfileImage: null
    };

    this.assignSelectedClass = this.assignSelectedClass.bind(this);
    this.logOutUser = this.logOutUser.bind(this);
  }

  async componentDidMount(){
    let that = this;
    const userInfo = this.context.state.internalBackendUserApi.getUserInfo();
    userInfo.then((response) => response.json())
        .then((responseJson) => {
          if(responseJson["status"] === "error"){
            // JWK Re-Auth Needed
            if(responseJson["error_type"] === "error_jwk_auth"){
              console.log("Token Invalid Let's Renew");
              console.log(this.context.state.cotter.logOut());
              this.context.redirectUser("/", 500);
            }
            // Provision Needed
            if(responseJson["error_type"] === "user_not_found"){
              let userProvision = this.context.state.internalBackendUserApi.doProvisionUser();
              userProvision.then((responseJson) => {
                console.log(responseJson);
              }).catch((error) => {
                  console.error(error);
              });
              window.location.reload();
            }
          } else {
            // console.log(responseJson.data.personal.profile.profile_image)
            that.setState({currUserName: responseJson.data.username});
            that.setState({currUserLinks: responseJson.data.personal.links});
            that.setState({currUserProfileImage: responseJson.data.personal.profile.profile_image});
          }
        })
        .catch((error) => {
            console.error(error);
        });
  }

  assignSelectedClass(n_id) {
    return this.state.selectedTab === n_id ? this.state.selectedClass : this.state.normalClass;
  }

  logOutUser() {
    let fCotter = this.context.state.cotter;
    // console.log(fCotter.getLoggedInUser())
    fCotter.logOut();
    this.context.redirectUser("/", 2000);
    return 
  }
  
  render() {
    return (
      <div>
          <div className="flex flex-row my-4 px-8 justify-between">
            <div className="flex flex-row my-4 text-center">
              <LiquidLinkLogo className="dashboard-logo" onClick={() => window.location.href = "/"}/>
            </div>
            <div className="flex flex-col my-4 text-center items-center">
              <img className="dashboard-profile-image justify-center mx-2"
                onClick={() => window.location.href = "/"}
                src={this.state.currUserProfileImage !== "" ? this.state.currUserProfileImage : default_profile_image_blue}
                alt="Profile Logo"/>
              <a target="_blank" rel="noopener noreferrer" href={`/${this.state.currUserName}`}><h2 className="font-bold text-gray-500 underline">{`@${this.state.currUserName}`}</h2></a>
            </div>
          </div>
        <div className="overflow-visible flex items-center justify-center">
          <div className="flex-row w-11/12">
            <ul className="flex border-b">
              <li className="-mb-px mr-1">
                <a className={this.assignSelectedClass("overview")} onClick={() => this.setState({selectedTab: "overview"})} href="#overview">Overview</a>
              </li>
              <li className="mr-1">
                <a className={this.assignSelectedClass("links")} onClick={() => this.setState({selectedTab: "links"})} href="#links">Links</a>
              </li>
              <li className="mr-1">
                <a className={this.assignSelectedClass("settings")} onClick={() => this.setState({selectedTab: "settings"})} href="#settings">Settings</a>
              </li>
            </ul>
            {this.state.selectedTab === "overview" ? <DashboardOverview />: null}
            {this.state.selectedTab === "links" ? <DashboardLinks currUserLinks={this.state.currUserLinks} />: null}
            {this.state.selectedTab === "settings" ? <DashboardSettings currUserName={this.state.currUserName} currUserProfileImage={this.state.currUserProfileImage}/>: null}
          </div>
        </div>
        <div className="flex w-full bottom-0 items-center justify-center my-4">
          <button className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded" onClick={this.logOutUser}>
            Log Out
          </button>
        </div>
      </div>
    );
  }
}

class LoginRegister extends React.Component {
  static contextType = MainAppContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      overrideHeight: 'auto'
    };
  }
  
  wait(timeout) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
  }
  
  async componentDidMount(){
    if (this.context.isAuthenticated()){
      // console.log("User is auth already, redirect to dashboard!")
      this.context.redirectUser("/m/dashboard", 1000);
    }
    
    // Init Cotter for Logins/Registers
    let fCotter = this.context.state.cotter;
    fCotter.signInWithWebAuthnOrLink();
    await this.wait(2100);
    await this.setState({isLoading: false, overrideHeight: "20em"});
    
    // Bind cotter login to page.
    try {
      fCotter.showEmailForm()
      .then(response => {
        console.log("Response while trying to login", JSON.stringify(response)); // show the response in our state
        if(response.auth_required === true){
          // Do nothing user need to enter there code
        } else {
          // set our JWT to localStorage
          localStorage.setItem("cotterJWT", response["oauth_token"]["access_token"])
          localStorage.setItem("cotterEmail", response["email"])
          this.context.redirectUser("/m/dashboard", 1000);
        }
        this.setState({isLoading: false})
      })
      .catch(err => console.log(err));
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Page Redirect too Fast! .. Login instance wasn't loaded.")
      }
    }
    
  }

  render() {


    return (
        <div className="flex flex-row min-h-screen justify-center items-center">
          <div className="flex-row">
            <span className="img-circle">
              <LiquidLinkLogo className="home-logo" onClick={() => window.location.href = "/"}/>
              </span>
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                {this.state.isLoading ?
                  <ScaleLoader
                    size={150}
                    color={"#0087eb"}
                    loading={this.state.isLoading}
                  />
                :
                <div id="login-container">
                  {/* <div className="flex-row items-center justify-center h-auto h-8">
                    {this.props.actionType === "login" ? 
                      <h1 className="font-extrabold">Log in</h1>
                    : 
                      <h1 className="font-extrabold">Register</h1>}
                  </div> */}
                  {/* {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [PasswordlessPreBuiltUI])} */}
                </div>
                }
            </div>
          </div>
        </div>
    )
  }
}

class UserPagePath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <UserView />
    );
  }
}

export default App;
export { MainAppContext };

