export default class BackendUserApi {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    _getEmail(){
        return localStorage.getItem("cotterEmail")
    }

    _getCotterJWTToken(){
        return localStorage.getItem("cotterJWT")
    }

    _getAuthHeaders(){
        let authHeaders = {
            'Authorization': `X-Client-Cotter-Json-Jwt-Token ${this._getCotterJWTToken()}; X-Client-Email ${this._getEmail()}`,
            'Content-Type': 'application/json'
        }
        return authHeaders;
    }

    getPublicUserInfo(username){
        let _authHeaders = {
            'Content-Type': 'application/json'
        }
        return fetch(this.baseURL + `/api/v1/user/public_info?u=${username}`, { 
            method: 'get', 
            headers: new Headers(_authHeaders), 
        })
    }

    getUserInfo(){
        return fetch(this.baseURL + "/api/v1/user/info", { 
            method: 'get', 
            headers: new Headers(this._getAuthHeaders()), 
            body: null
        })
    }

    /**
     * {
     *   "data": {
     *       "title": "This is a example title",
     *       "url": "https://example_url.com",
     *       "enabled": true
     *       }
     *   }
     */
    addUserLink(data){
        return fetch(this.baseURL + "/api/v1/user/addlink", { 
            method: 'post', 
            headers: new Headers(this._getAuthHeaders()), 
            body: JSON.stringify(data)
        })
    }

    modifyUserLink(data){
        let headers = this._getAuthHeaders();
        headers['Content-Type'] = 'application/json';
        return fetch(this.baseURL + "/api/v1/user/modifylink", { 
            method: 'post', 
            headers: new Headers(headers), 
            body: JSON.stringify(data)
        })
    }

    modifyUsername(data){
        let headers = this._getAuthHeaders();
        headers['Content-Type'] = 'application/json';
        return fetch(this.baseURL + "/api/v1/user/modifyusername", { 
            method: 'post', 
            headers: new Headers(headers), 
            body: JSON.stringify(data)
        })
    }

    deleteUserLink(data){
        let headers = this._getAuthHeaders();
        headers['Content-Type'] = 'application/json';
        return fetch(this.baseURL + "/api/v1/user/deletelink", { 
            method: 'post', 
            headers: new Headers(headers), 
            body: JSON.stringify(data)
        })
    }

    doProvisionUser(data){
        return fetch(this.baseURL + "/api/v1/user/provisioncotter", { 
            method: 'post', 
            headers: new Headers(this._getAuthHeaders()), 
            body: JSON.stringify(data)
        })
    }

    uploadUserProfileImage(imgData){
        let headers = this._getAuthHeaders();
        delete headers['Content-Type']; // delete here so we can auto-assign by data type
        const options = {
            method: 'POST',
            body: imgData,
            headers: headers
          };
        return fetch(this.baseURL + "/api/v1/user/upload_profile_image", options)
    }
}